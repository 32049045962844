/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

const { config } = require('@fortawesome/fontawesome-svg-core')

exports.onInitialClientRender = () => {
  /* Prevents fontawesome auto css insertion */
  config.autoAddCss = false
}