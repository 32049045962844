exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-applications-js": () => import("./../../../src/pages/applications.js" /* webpackChunkName: "component---src-pages-applications-js" */),
  "component---src-pages-download-cytoinsights-js": () => import("./../../../src/pages/download/cytoinsights.js" /* webpackChunkName: "component---src-pages-download-cytoinsights-js" */),
  "component---src-pages-download-decart-js": () => import("./../../../src/pages/download/decart.js" /* webpackChunkName: "component---src-pages-download-decart-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-info-js": () => import("./../../../src/pages/legal-info.js" /* webpackChunkName: "component---src-pages-legal-info-js" */),
  "component---src-pages-mikroscan-and-urine-cytology-js": () => import("./../../../src/pages/Mikroscan-and-Urine-Cytology.js" /* webpackChunkName: "component---src-pages-mikroscan-and-urine-cytology-js" */),
  "component---src-pages-private-policy-js": () => import("./../../../src/pages/private-policy.js" /* webpackChunkName: "component---src-pages-private-policy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signout-js": () => import("./../../../src/pages/signout.js" /* webpackChunkName: "component---src-pages-signout-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-term-of-service-js": () => import("./../../../src/pages/term-of-service.js" /* webpackChunkName: "component---src-pages-term-of-service-js" */),
  "component---src-pages-urine-cytology-js": () => import("./../../../src/pages/Urine-Cytology.js" /* webpackChunkName: "component---src-pages-urine-cytology-js" */)
}

